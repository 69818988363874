import React from 'react';
import aboutustopbanner from '../assets/img/ABOUT-US-BANNER.jpg';
import chairman from '../assets/board/Mohd.Akhter.jpg';
import tohidur from '../assets/board/TouhidurRahman.jpg';
import rahimdullah from '../assets/board/rahimUddaulah_dirct.jpg';
import asaduzzaman from '../assets/board/mdAshaduzzaman.jpg';
import yousuf from '../assets/board/WahidMuhammadYousuf.jpg';
import bahar from '../assets/board/Mostafa-zamanul-bahar.jpg';
import rajibhasan from '../assets/board/rajib-hasan-ceo.jpg';
const Boardofdirectors = () => {
    return (
        <div>
               <div className="relative w-full">
                <img
                    src={aboutustopbanner}
                    alt="board of director"
                    className="w-full h-[200px] object-cover"
                />
                 <div className="absolute inset-0 flex items-center justify-center">
                    <h1 className="text-white shadow text-center lg:text-4xl font-bold">
                    Board of Directors
                    </h1>
                </div>
                </div>
     

            <div className='px-2  md:px-28 lg:px-64'>

            <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
            <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={chairman} alt="Chairman" />
                </div>

                
                <div className='col-span-5 px-14'>
                    <h1 className="text-xl text-start mt-2 font-bold">Mohd. Akhter</h1>
                    <p className="text-lg text-start font-semibold">Chairman</p>
                    <p className="text-justify text-lg">
                    Mr. Mohd. Akther is the Chairman of Prime Islami Life Insurance Limited. He holds a Masters of Commerce degree and is a renowned businessman. He is the Managing Director of Gumti Textiles Limited, Al-Razee Spinning Mills Limited, and Chairman of LandScape Limited. With over 30 years of experience in managing textile enterprises and 15 years in the RMG sector in Bangladesh, he has held significant roles in companies like GMG Industrial Corporation, Palash Industrial Enterprise, and Rahim Textile. He is a former director of Generation Next Fashions Limited.
                    </p>
                </div>
                </div>
                <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
            <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={tohidur} alt="tohidur" />
                </div>

                
                <div className='col-span-5 px-14'>
                    <h1 className="text-xl text-start mt-2 font-bold">Mohammad Touhidur Rahman, FCA</h1>
                    <p className="text-lg text-start font-semibold">Director</p>
                    <p className="text-justify text-lg">
                    Mr. Mohammad Touhidur Rahman, FCA is Director of PISL's Parent Company Prime Islami Securities Limited. He is the partner of M/s. Howlader Yunus & Co, a prestigious Chartered Accountants firm. He has serviced more than twelve years in different fields including Banking for more than eight years.
                    </p>
                </div>
                </div>
                <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
            <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={rahimdullah} alt="rahimdullah" />
                </div>

                
                <div className='col-span-5 px-14'>
                    <h1 className="text-xl text-start mt-2 font-bold">Rahim Uddaulah Chowdhury</h1>
                    <p className="text-lg text-start font-semibold">Director</p>
                    <p className="text-justify text-lg">
                    Mr. Rahim Uddaulah Chowdhury was born in a respectable Muslim family from Banshkhali, Chattogram. He obtained Master’s degree in the year 1981 from the University of Chittagong. He has about 35 year(s) of Job experiences in various renowned Corporate houses and Multinational Companies including Berger Paints Bangladesh Limited. He participated many Training Program and workshop at home and abroad in his carrier life. In his long working experience in different sector he gathered vast knowledge and developed expertise in different discipline such as Administration, Supply Chain Management, Sales & Marketing. He is also associated with many philanthropic activities in his localities.
                    </p>
                </div>
                </div>
                <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
            <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={asaduzzaman} alt="Chairman" />
                </div>

                
                <div className='col-span-5 px-14'>
                    <h1 className="text-xl text-start mt-2 font-bold">Md. Ashaduzzaman</h1>
                    <p className="text-lg text-start font-semibold">Director</p>
                    <p className="text-justify text-lg">
                    Mr. Md. Ashaduzzaman is an Executive Director of M/s. Ramisha BD Limited and representative Director of Prime Islami Life Insurance Limited. He has obtained Master in Commerce (M.Com). He is one of the Director of Prime Insurance Company Limited. He has also served as the Executive Director of Fareast Securities Limited. Mr. Ashaduzzaman also serves as an Executive in various group of Companies
                    </p>
                </div>
                </div>
                <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
            <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={yousuf} alt="Chairman" />
                </div>

                
                <div className='col-span-5 px-14'>
                    <h1 className="text-xl text-start mt-5 font-bold">Wahid Muhammad Yousuf</h1>
                    <p className="text-lg text-start font-semibold">Director</p>
                    <p className="text-justify text-lg">
                    Wahid Muhammad Yousuf, Director of Prime Islami Securities Limited.
                    </p>
                </div>
                </div>

                <div className="grid mt-5 mb-10 grid-cols-1 md:grid-cols-6 gap-4">
                  <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={rajibhasan} alt="rajibhasan" />
                </div>

                
                <div className='col-span-5 px-14 mt-24'>
                    <h1 className="text-xl text-start mt-0 font-bold">Md. Rajib Hasan, CGIA</h1>
                    <p className="text-lg text-start font-semibold">Chief Executive Officer</p>
                   
                </div>
                </div>

            </div>





        </div>
    );
};

export default Boardofdirectors;