import React from 'react';
import logo from '../assets/icon/filic.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='mt-16'>
          
            <footer class="p-4 bg-[#00838F] text-white shadow md:px-6 md:py-4 dark:bg-[#00838F]">

            <p className='text-[#80DEEA]'>
                <span className='text-white'>Important Links : </span>
                <a href='https://www.sec.gov.bd/' target='_blank' rel='noopener noreferrer'>
                    <span>BSEC</span>
                </a> 
                <a href='http://www.dsebd.org/' target='_blank' rel='noopener noreferrer'>
                    <span className='ml-3'>DSE</span>
                </a> 
                <a href='https://www.cse.com.bd/' target='_blank' rel='noopener noreferrer'>
                    <span className='ml-3'>CSE</span>
                </a> 
                <a href='https://www.cdbl.com.bd/' target='_blank' rel='noopener noreferrer'>
                    <span className='ml-3'>CDBL</span>
                </a> 
                </p>


                <span class="block mt-3 text-sm bg-[#00838F] text-white sm:text-center dark:text-gray-400">
                    © 2024 <a href="#" class="hover:underline">Prime Securities Islami LTD</a>. All Rights Reserved.
                </span>
            </footer>

        </div>
    );
};

export default Footer;