import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/icon/primelogo.jpg';
import { AiOutlineHome } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { Dropdown } from 'flowbite-react';
import { BsYoutube } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsArrowUpShort } from "react-icons/bs";
import { BsArrowDownShort } from "react-icons/bs";
const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    
    return (
         <div>

            {/* <!-- Section: Design Block --> */}
            <section class="mb-0">
                <nav class="navbar navbar-expand-lg bg-[#00838F] lg:px-60 shadow-md py-4 relative flex items-center w-full justify-between">
                    <div class="px-6 w-full flex flex-wrap items-center justify-between">
                        <div class="flex items-center">
                            <button
                                class="navbar-toggler border-0 py-3 lg:hidden leading-none text-xl bg-transparent text-white hover:text-white focus:text-white transition-shadow duration-150 ease-in-out mr-2"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContentY"
                                aria-controls="navbarSupportedContentY"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    class="w-5"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                                    ></path>
                                </svg>
                            </button>
                            
                    <img className='w-16  shadow-lg bg-white rounded p-1 hidden lg:block' src={logo} />
               
                   <div className=''>
                    <h1  className=" text-sm lg:text-2xl lg:px-3 text-white font-bold"  style={{ fontFamily: "'Poppins', sans-serif" }}>
                        PRIME ISLAMI SECURITIES LTD.
                    </h1>
                    <p 
                        className="btn btn-ghost normal-case text-sm text-end lg:block hidden lg:px-3 text-[#DDDDDD] font-semibold" 
                        style={{ fontFamily: "'Poppins', sans-serif", letterSpacing: '0.05em', lineHeight: '1.5', textTransform: 'uppercase' }}>
                        Informed :: Insightful :: Investment
                        </p>
                    </div>

                    
                        </div>
                        <div class="navbar-collapse collapse grow items-center" id="navbarSupportedContentY">
                            <ul class="navbar-nav mr-auto lg:flex lg:flex-row lg:px-24">

                                <li class="nav-item flex text-center bg-[#0097A7] pl-3 py-2.5  pr-0 mt-1 p-1.5 mr-3 rounded hover:text-md hover:bg-[#00ACC1]">
                                 <AiOutlineHome className='mt-1 text-white mr-1' /> 
                                 <NavLink className='text-white pr-3 font-weight-bold ' to='/' activeStyle={{color: "#005005",textDecoration:"none"}}>Home</NavLink>
                                </li>
                                <div className="relative">
                                    <button
                                        onClick={toggleDropdown}
                                        className="flex items-center bg-[#0097A7] py-2.5 w-full text-white px-4  mt-1 rounded hover:bg-[#00ACC1] focus:outline-none"
                                    >
                                         About us 
                                      
                                       {isOpen ? <BsArrowUpShort className='text-white w-5 h-5  mt-1 transition duration-300 ease-in-out hover:scale-110' />   : 
                                       <BsArrowDownShort className='text-white w-5 h-5 mt-1 transition duration-300 ease-in-out hover:scale-110' />}
                                      
                                       
                                    </button>
                                    {isOpen && (
                                        <div className="absolute left-0 mt-2 w-48 bg-[#0097A7] text-white rounded-lg shadow-lg z-10 overflow-hidden">
                                        <ul className="list-none p-0 m-0">
                                            <li className="px-2 py-2.5 text-start  ml-1 hover:bg-[#00ACC1]">
                                            <NavLink to="/about" className="block text-sm">Company Profile</NavLink>
                                            </li>
                                            <li className="my-0 border-t border-white"></li>
                                            <li className="px-2 py-2.5 text-start ml-1 hover:bg-[#00ACC1]">
                                            <NavLink to="/board-of-directors" className="block text-sm">Board Of Directors</NavLink>
                                            </li>
                                            <li className="my-0 border-t border-white"></li>
                                            {/* <li className="px-2 py-2.5 text-start ml-1  hover:bg-[#00ACC1]">
                                            <NavLink to="/corporate-management" className="block text-sm">Corporate Management</NavLink>
                                            </li> */}
                                          
                                        </ul>
                                        </div>
                                    )}
                                 </div>
                                <li class="nav-item flex text-center bg-[#0097A7] pl-2   pr-0 mt-1 ml-2 py-2.5 p-1.5 mr-0 rounded hover:text-md hover:bg-[#00ACC1]">
                                      <NavLink className='text-white pr-3' to='/services'>
                                      Services</NavLink>
                                </li>

                                <li class="nav-item flex text-center bg-[#0097A7] pl-2   pr-0 mt-1 ml-2 py-2.5 p-1.5 mr-0 rounded hover:text-md hover:bg-[#00ACC1]">
                                      <NavLink className='text-white pr-3' to='/'>
                                      News & Events</NavLink>
                                </li>
                            
                                <li class="nav-item flex text-center bg-[#0097A7]  ml-2 pr-0 mt-1 py-2.5 p-1.5 pl-2 mr-3 rounded hover:text-md hover:bg-[#00ACC1]">
                                <NavLink className='text-white pr-3' to='/contact-us'>Contact us</NavLink>
                                </li>

                            
                                
                            </ul>
                        </div>
                        <div class="flex items-center lg:ml-auto">
                        <BsFacebook className='text-white w-5 h-6 transition duration-300 ease-in-out hover:scale-150' />  
                        <AiFillTwitterCircle className='text-white w-6 h-6 ml-4 transition duration-300 ease-in-out hover:scale-150'/>
                        <BsYoutube className='text-white w-6 h-6 ml-4 transition duration-300 ease-in-out hover:scale-150' /> 
                        </div>
                    </div>
                </nav>

            </section>
            {/* <!-- Section: Design Block --> */}

        </div>
    );
};

export default Navbar;