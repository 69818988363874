import { Carousel } from 'flowbite-react';
import React from 'react';
import slider from '../assets/img/pslider.jpg';
import slider1 from '../assets/img/pslider1.jpg';
import slider2 from '../assets/img/pslider2.jpg';
const Slider = () => {
    return (
        <div>



<div className="h-48 md:h-[400px] lg:h-[600px]">
      <Carousel>
        <img  className="h-48  md:h-[400px] lg:h-[600px]"  src={slider} alt="..." />
        <img className="h-48 md:h-[400px]  lg:h-[600px]"  src={slider1} alt="..." />
        <img className="h-48  md:h-[400px] lg:h-[600px]"  src={slider2} alt="..." />
        <img className=" h-48 md:h-[400px]  lg:h-[600px]" src={slider1} alt="..." />
        <img  className=" h-48  md:h-[400px] lg:h-[600px]" src={slider2} alt="..." />
      </Carousel>
    </div>
        </div>
    );
};

export default Slider;