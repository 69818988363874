import React from 'react';
import aboutustopbanner from '../assets/img/ABOUT-US-BANNER.jpg';
import rajibhasan from '../assets/board/rajib-hasan-ceo.jpg';
import kobirul from '../assets/board/S-M-Kabirul-Islam.jpg';
import jahangir from '../assets/board/Jahangir.jpg';
import abusayem from '../assets/board/AbuSayem.jpg';
import Moudud from '../assets/board/moududAhammedACS.jpg';
import jahangir_ctg from '../assets/board/Jahangir_ctg.jpg';
import munna from '../assets/board/Munna.jpg';
import nowshin from '../assets/board/nawshinTabassum.jpg';
import Services from './Services';



const Servicesall = () => {
    return (
        <div>
               <div className="relative w-full">
                <img
                    src={aboutustopbanner}
                    alt="board of director"
                    className="w-full h-[200px] object-cover"
                />
                 <div className="absolute inset-0 flex items-center justify-center">
                    <h1 className="text-white shadow text-center lg:text-4xl font-bold">
                    Our Services
                    </h1>
                </div>
                </div>
     

           
              <Services/>
           

          





        </div>
    );
};

export default Servicesall;