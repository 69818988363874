import React from 'react';
import capital from '../assets/icon/market-capitalization.png';
import customer from '../assets/icon/customer.png';
import reseacrh from '../assets/icon/research.png';
import datacenter from '../assets/icon/data-center.png';
import process from '../assets/icon/process.png';
import commisionrate from '../assets/icon/interest.png';
import customer_service from '../assets/icon/customer-service.png';
import collaboration from '../assets/icon/collaboration.png';

const Strength = () => {

    return (
        <div className='px-2  md:px-28 lg:px-64'>
              
             <p
                className='m-8 font-bold lg:text-2xl text-[#00838F] underline decoration-[#00838F] decoration-1 hover:decoration-4 hover:decoration-[#FF5722]'
                style={{ textDecorationThickness: '2px', textUnderlineOffset: '2px' }}
                onMouseEnter={(e) => e.target.style.textUnderlineOffset = '6px'}
                onMouseLeave={(e) => e.target.style.textUnderlineOffset = '2px'}
                >
                OUR STRENGTH
                </p>

                <div className="mt-8 mb-5 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div class="block max-w-full rounded bg-white text-surface shadow-2xl border dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
                    <div class="p-3 flex">
                        <img className="rounded-t-md w-16 h-16" src={capital} alt="Market Capitalization" />
                   
                    <p className="sm:text-xs mt-1 md:text-sm lg:text-md text-justify  dark:text-white ml-5 p-1">
                    Unparalleled expertise in investment management within Bangladesh capital market.
                        </p>
                    </div>

                    </div>


                    <div class="block max-w-full rounded bg-white text-surface shadow-2xl border dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
                    <div class="p-3 flex">
                        <img className="rounded-t-md w-16 h-16" src={customer} alt="Market Capitalization" />
                   
                    <p className="sm:text-xs mt-3 md:text-sm lg:text-md text-justify  ml-5 dark:text-white p-1">
                    Experience in handling large institutional and retail clientele.
                        </p>
                    </div>


                    </div>
                    <div class="block max-w-full rounded bg-white text-surface shadow-2xl border dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
                    <div class="p-3 flex">
                        <img className="rounded-t-md w-16 h-16" src={reseacrh} alt="Market Capitalization" />
                   
                    <p className="sm:text-xs mt-3 md:text-sm lg:text-md text-justify dark:text-white p-1 ml-5">
                       Experienced and well-qualified research team.
                        </p>
                    </div>
                    </div>

                    <div class="block max-w-full rounded bg-white text-surface shadow-2xl border dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
                    <div class="p-3 flex">
                        <img className="rounded-t-md w-16 h-16" src={collaboration} alt="Market Capitalization" />
                   
                    <p className="sm:text-xs mt-3 md:text-sm lg:text-md text-justify dark:text-white p-1 ml-5">
                       Experienced and well-qualified collaboration with customer.
                        </p>
                    </div>
                    </div>
                </div>

                <div className="mt-5 mb-8 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div class="block max-w-full rounded bg-white text-surface shadow-2xl border dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
                    <div class="p-3 flex">
                        <img className="rounded-t-md w-16 h-16" src={datacenter} alt="Market Capitalization" />
                   
                    <p className="sm:text-xs mt-3 md:text-sm lg:text-md  dark:text-white p-1">
                    State-of-the-art ICT infrastructure.
                        </p>
                    </div>

                    </div>


                    <div class="block max-w-full rounded bg-white text-surface shadow-2xl border dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
                    <div class="p-3 flex">
                        <img className="rounded-t-md w-16 h-16" src={process} alt="Market Capitalization" />
                   
                    <p className="sm:text-xs mt-3 md:text-sm lg:text-md text-justify  dark:text-white p-1 ml-6">
                    Fast and efficient trade execution.
                        </p>
                    </div>


                    </div>
                    <div class="block max-w-full rounded bg-white text-surface shadow-xl border dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
                    <div class="p-3 flex">
                        <img className="rounded-t-md w-16 h-16" src={commisionrate} alt="Market Capitalization" />
                   
                    <p className="sm:text-xs mt-4 md:text-sm lg:text-md  text-justify dark:text-white p-1 ml-5">
                    Competitive commission rate.
                        </p>
                    </div>
                    </div>
                    <div class="block max-w-full rounded bg-white text-surface shadow-xl border dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
                    <div class="p-3 flex">
                        <img className="rounded-t-md w-16 h-16" src={customer_service} alt="Market Capitalization" />
                   
                    <p className="sm:text-xs mt-4 md:text-sm lg:text-md  text-justify dark:text-white p-1 ml-5">
                    Dedicated customer service.
                        </p>
                    </div>
                    </div>
                </div>

        </div>
    );
};

export default Strength;