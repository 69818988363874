import { Card } from 'flowbite-react';
import React from 'react';
import Service1 from '../assets/services/one.jpg';
import Service2 from '../assets/services/five.jpg';
import Service3 from '../assets/services/three.jpg';

import Service4 from '../assets/services/four.jpg';
import Service5 from '../assets/services/s5.png';
import Service6 from '../assets/services/s6.png';



const Services = () => {
return (
<div className='px-2  md:px-28 lg:px-64'>
<p
  className='m-8 font-bold lg:text-2xl text-[#00838F] underline decoration-[#00838F] decoration-1 hover:decoration-4 hover:decoration-[#FF5722]'
  style={{ textDecorationThickness: '2px', textUnderlineOffset: '2px' }}
  onMouseEnter={(e) => e.target.style.textUnderlineOffset = '6px'}
  onMouseLeave={(e) => e.target.style.textUnderlineOffset = '2px'}
>
  OUR ALL SERVICES
</p>


    

<div className="mt-8 mb-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
     <div class="block max-w-full rounded bg-white text-surface shadow-2xl dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img
              class="rounded-t-md bg-cover bg-no-repeat"
              src={Service1}
              alt="" />
          </div>
          <div class="p-3">
          <p className="sm:text-xs md:text-sm lg:text-md font-bold dark:text-white p-0">
                Retail Brokerage Services
              </p>
          </div>

          
        </div>
        <div
          class="block max-w-full rounded-lg bg-white text-surface shadow-2xl dark:bg-surface-dark dark:text-white transition duration-300 ease-in-out hover:scale-105 ">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img
              class="rounded-t-lg"
              src={Service2}
              alt="" />
          </div>
          <div class="p-3">
          <p className="sm:text-xs md:text-sm lg:text-md font-bold dark:text-white p-0">
          Corporate & HNIs
              </p>
          </div>
        </div>


        <div
          class="block max-w-full rounded-lg bg-white text-surface shadow-2xl dark:bg-surface-dark dark:text-white transition duration-300 ease-in-out hover:scale-105 ">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img
              class="rounded-t-lg"
              src={Service3}
              alt="" />
          </div>
          <div class="p-3">
          <p className="sm:text-xs md:text-sm lg:text-md font-bold dark:text-white p-0">
          Foreign & NRB Service
              </p>
          </div>
        </div>

        <div
          class="block max-w-full rounded-lg bg-white text-surface shadow-2xl dark:bg-surface-dark dark:text-white transition duration-300 ease-in-out hover:scale-105 ">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img
              class="rounded-t-lg"
              src={Service4}
              alt="" />
          </div>
          <div class="p-3">
          <p className="sm:text-xs md:text-sm lg:text-md font-bold dark:text-white p-0">
          Retail Brokerage Services
              </p>
          </div>
        </div>
  </div>
  <div className="mt-8 mb-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
     <div class="block max-w-full rounded bg-white text-surface shadow-2xl dark:bg-surface-dark dark:text-white  transition duration-300 ease-in-out hover:scale-105 ">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img
              class="rounded-t-md"
              src={Service5}
              alt="" />
          </div>
          <div class="p-3">
          <p className="sm:text-xs md:text-sm lg:text-md font-bold dark:text-white p-0">
                Retail Brokerage Services
              </p>
          </div>

          
        </div>

        <div
          class="block max-w-full rounded-lg bg-white text-surface shadow-2xl dark:bg-surface-dark dark:text-white transition duration-300 ease-in-out hover:scale-105 ">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img
              class="rounded-t-lg"
              src={Service3}
              alt="" />
          </div>
          <div class="p-3">
          <p className="sm:text-xs md:text-sm lg:text-md font-bold dark:text-white p-0">
          Foreign & NRB Service
              </p>
          </div>
        </div>
        <div
          class="block max-w-full rounded-lg bg-white text-surface shadow-2xl dark:bg-surface-dark dark:text-white transition duration-300 ease-in-out hover:scale-105 ">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img
              class="rounded-t-lg"
              src={Service6}
              alt="" />
          </div>
          <div class="p-3">
          <p className="sm:text-xs md:text-sm lg:text-md font-bold dark:text-white p-0">
          Corporate & HNIs
              </p>
          </div>
        </div>


 

        <div
          class="block max-w-full rounded-lg bg-white text-surface shadow-2xl dark:bg-surface-dark dark:text-white transition duration-300 ease-in-out hover:scale-105 ">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img
              class="rounded-t-lg"
              src={Service4}
              alt="" />
          </div>
          <div class="p-3">
          <p className="sm:text-xs md:text-sm lg:text-md font-bold dark:text-white p-0">
          Retail Brokerage Services
              </p>
          </div>
        </div>
  </div>

</div>

    );
};

export default Services;