import React from 'react';
import Slider from './Slider';
import Services from './Services';
import Strength from './Strength';


const Home = () => {
    return (
        <div className=''>
            <Slider />
             <Services/>
             <Strength/>
        </div>
    );
};

export default Home;