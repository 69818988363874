import React from 'react';
import notfound from '../assets/img/notfound.jpg';

const Notfound = () => {
    return (
        <div>
              <img src={notfound} alt="" />
        </div>
    );
};

export default Notfound;