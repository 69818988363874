import React from 'react';
import aboutustopbanner from '../assets/img/ABOUT-US-BANNER.jpg';
import rajibhasan from '../assets/board/rajib-hasan-ceo.jpg';
import kobirul from '../assets/board/S-M-Kabirul-Islam.jpg';
import jahangir from '../assets/board/Jahangir.jpg';
import abusayem from '../assets/board/AbuSayem.jpg';
import Moudud from '../assets/board/moududAhammedACS.jpg';
import jahangir_ctg from '../assets/board/Jahangir_ctg.jpg';
import munna from '../assets/board/Munna.jpg';
import nowshin from '../assets/board/nawshinTabassum.jpg';



const Corporatemanagement = () => {
    return (
        <div>
               <div className="relative w-full">
                <img
                    src={aboutustopbanner}
                    alt="board of director"
                    className="w-full h-[200px] object-cover"
                />
                 <div className="absolute inset-0 flex items-center justify-center">
                    <h1 className="text-white shadow text-center lg:text-4xl font-bold">
                    Corporate Management
                    </h1>
                </div>
                </div>
     

            <div className='px-2  md:px-28 lg:px-64'>

            <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
            <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={rajibhasan} alt="Chairman" />
                </div>

                
                <div className='col-span-5 px-14 mt-24'>
                    <h1 className="text-xl text-start mt-2 font-bold">Md. Rajib Hasan, CGIA</h1>
                    <p className="text-lg text-start font-semibold">Chief Executive Officer (cc)</p>
                    {/* <p className="text-justify text-lg">
                    Mr. Mohd. Akther is the Chairman of Prime Islami Life Insurance Limited. He holds a Masters of Commerce degree and is a renowned businessman. He is the Managing Director of Gumti Textiles Limited, Al-Razee Spinning Mills Limited, and Chairman of LandScape Limited. With over 30 years of experience in managing textile enterprises and 15 years in the RMG sector in Bangladesh, he has held significant roles in companies like GMG Industrial Corporation, Palash Industrial Enterprise, and Rahim Textile. He is a former director of Generation Next Fashions Limited.
                    </p> */}
                </div>
                </div>
                <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
            <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={kobirul} alt="tohidur" />
                </div>

                
                <div className='col-span-5 px-14 mt-20'>
                    <h1 className="text-xl text-start mt-2 font-bold">S. M. Kabirul Islam</h1>
                    <p className="text-lg text-start font-semibold">Senior Manager (Head of Business Development)</p>
                    {/* <p className="text-justify text-lg">
                    Mr. Mohammad Touhidur Rahman, FCA is Director of PISL's Parent Company Prime Islami Securities Limited. He is the partner of M/s. Howlader Yunus & Co, a prestigious Chartered Accountants firm. He has serviced more than twelve years in different fields including Banking for more than eight years.
                    </p> */}
                </div>
                </div>
                <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
            <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={jahangir} alt="rahimdullah" />
                </div>

                
                <div className='col-span-5 px-14 mt-20'>
                    <h1 className="text-xl text-start mt-2 font-bold">
                    MD. Jahangir Alam</h1>
                    <p className="text-lg text-start font-semibold">Senior Manager (Trading & Business Development)</p>
                    {/* <p className="text-justify text-lg">
                    Mr. Rahim Uddaulah Chowdhury was born in a respectable Muslim family from Banshkhali, Chattogram. He obtained Master’s degree in the year 1981 from the University of Chittagong. He has about 35 year(s) of Job experiences in various renowned Corporate houses and Multinational Companies including Berger Paints Bangladesh Limited. He participated many Training Program and workshop at home and abroad in his carrier life. In his long working experience in different sector he gathered vast knowledge and developed expertise in different discipline such as Administration, Supply Chain Management, Sales & Marketing. He is also associated with many philanthropic activities in his localities.
                    </p> */}
                </div>
                </div>
                <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
                <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={abusayem} alt="Chairman" />
                </div>

                
                <div className='col-span-5 px-14 mt-20'>
                    <h1 className="text-xl text-start mt-2 font-bold">Sheikh Md. Abu Sayem</h1>
                    <p className="text-lg text-start font-semibold">Deputy Manager (Head of Finance & Accounts)</p>
                    {/* <p className="text-justify text-lg">
                    Mr. Md. Ashaduzzaman is an Executive Director of M/s. Ramisha BD Limited and representative Director of Prime Islami Life Insurance Limited. He has obtained Master in Commerce (M.Com). He is one of the Director of Prime Insurance Company Limited. He has also served as the Executive Director of Fareast Securities Limited. Mr. Ashaduzzaman also serves as an Executive in various group of Companies
                    </p> */}
                </div>
                </div>
                <div className="grid mt-5 mb-5 grid-cols-1 md:grid-cols-6 gap-4">
            <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={Moudud} alt="Chairman" />
                </div>

                
                <div className='col-span-5 px-14 mt-20'>
                    <h1 className="text-xl text-start mt-5 font-bold">Al Helal Md. Moudud Ahammed ACS</h1>
                    <p className="text-lg text-start font-semibold">Deputy Manager, (Compliance Officer & Company Secretariat)</p>
                    {/* <p className="text-justify text-lg">
                    Wahid Muhammad Yousuf, Director of Prime Islami Securities Limited.
                    </p> */}
                </div>
                </div>

                <div className="grid mt-5 mb-10 grid-cols-1 md:grid-cols-6 gap-4">
                <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={jahangir_ctg} alt="Chairman" />
                </div>

                
                <div className='col-span-5 px-14 mt-32'>
                    <h1 className="text-xl text-start mt-0 font-bold">Jahangir Alam</h1>
                    <p className="text-lg text-start font-semibold">Asst. Manager & In-Charge
                    Chattogram Digital Booth</p>
                    {/* <p className="text-justify text-lg">
                    Mostafa Zamanul Bahar was born in 1971 in a renounce Muslim family. He obtained MSS degree from University of Dhaka in Economics. He has been working at Beximco Group since October, 1996 till date. At present his position in the company is Executive Director, Group Finance & Corporate Affairs. He also worked at American Express Bank from 1995 to 1996. He is Interested in Sports & Travelling, currently life member of DUEDAA (Dhaka University Economics Department Alumni Association), Bangladesh Economic Association, Salimullah Muslim Hall Alumni Association & Member of Dhaka Club Limited. He widely Travelled USA, UK, Japan, Australia, Singapore, Germany, Italy, France, China, Malaysia, India, Pakistan, Indonesia, Hongkong, Saudi Arabia, Arab Emirate etc.
                    </p> */}
                </div>
                </div>

                <div className="grid mt-5 mb-10 grid-cols-1 md:grid-cols-6 gap-4">
                <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={munna} alt="Chairman" />
                </div>

                
                <div className='col-span-5 px-14 mt-32'>
                    <h1 className="text-xl text-start mt-0 font-bold">Anamul Haq Munna</h1>
                    <p className="text-lg text-start font-semibold">Asst. Manager (Head of IT)</p>
                    {/* <p className="text-justify text-lg">
                    Mostafa Zamanul Bahar was born in 1971 in a renounce Muslim family. He obtained MSS degree from University of Dhaka in Economics. He has been working at Beximco Group since October, 1996 till date. At present his position in the company is Executive Director, Group Finance & Corporate Affairs. He also worked at American Express Bank from 1995 to 1996. He is Interested in Sports & Travelling, currently life member of DUEDAA (Dhaka University Economics Department Alumni Association), Bangladesh Economic Association, Salimullah Muslim Hall Alumni Association & Member of Dhaka Club Limited. He widely Travelled USA, UK, Japan, Australia, Singapore, Germany, Italy, France, China, Malaysia, India, Pakistan, Indonesia, Hongkong, Saudi Arabia, Arab Emirate etc.
                    </p> */}
                </div>
                </div>
                <div className="grid mt-5 mb-10 grid-cols-1 md:grid-cols-6 gap-4">
                <div className="relative overflow-hidden bg-cover bg-no-repeat shadow-xl border border-gray-300">
                <img className="rounded-t-md lg:w-full lg:h-full p-3  hover:scale-110 transition-transform duration-300 ease-in-out" src={nowshin} alt="Chairman" />
                </div>

                
                <div className='col-span-5 px-14 mt-32'>
                    <h1 className="text-xl text-start mt-0 font-bold">Nawshin Tabassum Chowdhury</h1>
                    <p className="text-lg text-start font-semibold">Head of HR & Admin</p>
                    {/* <p className="text-justify text-lg">
                    Mostafa Zamanul Bahar was born in 1971 in a renounce Muslim family. He obtained MSS degree from University of Dhaka in Economics. He has been working at Beximco Group since October, 1996 till date. At present his position in the company is Executive Director, Group Finance & Corporate Affairs. He also worked at American Express Bank from 1995 to 1996. He is Interested in Sports & Travelling, currently life member of DUEDAA (Dhaka University Economics Department Alumni Association), Bangladesh Economic Association, Salimullah Muslim Hall Alumni Association & Member of Dhaka Club Limited. He widely Travelled USA, UK, Japan, Australia, Singapore, Germany, Italy, France, China, Malaysia, India, Pakistan, Indonesia, Hongkong, Saudi Arabia, Arab Emirate etc.
                    </p> */}
                </div>
                </div>

            </div>





        </div>
    );
};

export default Corporatemanagement;