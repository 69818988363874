import React, { useEffect } from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import aboutustopbanner from '../assets/img/ABOUT-US-BANNER.jpg';
import aboutbanner from '../assets/img/innovation.jpg';

const About = () => {

    useEffect(() => {
        // Load the Facebook SDK script asynchronously
        if (window.FB) {
          window.FB.XFBML.parse();
        } else {
          (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v11.0";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        }
      }, []);
    return (

            <div className=''>
                 <div className="relative w-full">
                <img
                    src={aboutustopbanner}
                    alt="board of director"
                    className="w-full h-[200px] object-cover"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                    <h1 className="text-white shadow text-center text-2xl font-bold">
                    ABOUT PRIME SECURITIES
                    </h1>
                </div>
                </div>
                  
                <div className='px-2  md:px-28 lg:px-64'>


                <div className="mt-8 mb-8 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
                <div className="col-span-1 text-left lg:col-span-2  shadow-lg  p-2 mb-2 bg-body rounded">
                        {/* Facebook Page Plugin */}
                        <div className="fb-page"
                            data-tabs="timeline,events,messages"
                            data-href="https://www.facebook.com/profile.php?id=100069523436037"
                            data-width="5100"
                            data-height="750"
                            data-hide-cover="false">
                        </div>
                    </div>
                    <div
                    class="block col-span-1 ml-5 p-2  lg:col-span-3 max-w-full rounded-lg bg-white text-surface shadow-2xl dark:bg-surface-dark dark:text-white  ">
                    <div class="relative overflow-hidden bg-cover bg-no-repeat h-80">
                        <img
                        class="rounded"
                        src={aboutbanner}
                        alt="" />
                    </div>
                    <div class="p-3">
                    <p className="sm:text-xs md:text-sm lg:text-md font-bold dark:text-white p-0">
                    CREATING DIFFERENCE THROUGH INNOVATION
                    </p>
                    <h6 className='text-justify mt-3'>
                    Prime Islami Securities Limited (PISL) is a registered TREC Holder of both Dhaka Stock Exchange (DSE) and Chittagong Stock Exchange (CSE).
                    PISL aims to provide a full range of professional stock brokerage services to the institutional and individual investors (including NRBs) in Bangladesh as well as foreign institutions and individuals. With a view to ensure greater participation of the investors, PISL currently offers securities brokerage and depository participant services. <br /><br />
                    As a stock broker and stock dealer, the company buys and sells securities, bonds, debentures, commercial papers, treasury bills or any financial instruments on behalf of clients as well as for its own.  <br /> <br />
                    After commencing commercial operation in January 2011, within a very short period of time, PISL has established itself as a leading and reputable brokerage houses in the country. In addition to successfully creating a solid client base of satisfied institutional and retail customers, PISL has become one of the largest and most active dealers in the market. 
                    </h6>
                    </div>
                    </div>


                </div>
                
                </div>

            </div>

      
    );
};

export default About;