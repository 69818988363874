import React, { useState } from 'react';
import aboutustopbanner from '../assets/img/ABOUT-US-BANNER.jpg';

import { BiMap } from "react-icons/bi";
import { BiPhoneCall } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
const Contactus = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        queryType: '',
        message: ''
      });
    
      const [errors, setErrors] = useState({});
    
      // Handle form input changes
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      // Validate form inputs
      const validate = () => {
        let formErrors = {};
        
        if (!formData.name) formErrors.name = 'Name is required';
        if (!formData.email) formErrors.email = 'Email is required';
        if (!formData.phone) formErrors.phone = 'Phone is required';
        if (!formData.queryType) formErrors.queryType = 'Query Type is required';
        if (!formData.message) formErrors.message = 'Message is required';
    
        return formErrors;
      };
    
      // Handle form submission
      const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validate();
    
        if (Object.keys(formErrors).length === 0) {
          // Submit form data (send to API or handle accordingly)
          console.log('Form submitted successfully:', formData);
        } else {
          setErrors(formErrors);
        }
      };




    return (
        <div>
               <div className="relative w-full">
                <img
                    src={aboutustopbanner}
                    alt="board of director"
                    className="w-full h-[200px] object-cover"
                />
                 <div className="absolute inset-0 flex items-center justify-center">
                    <h1 className="text-white shadow text-center lg:text-4xl font-bold">
                    Contact Us
                    </h1>
                </div>
                </div>
     

            <div className='px-2  md:px-28 lg:px-64'>

            <div className="mt-8 mb-5   p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 bg-white text-surface shadow-2xl border dark:bg-surface-dark dark:text-white">
                    <div class="block max-w-full rounded   ">
                    <div class="p-3 flex">
                    
                        <BiMap className='w-24 h-24 text-[#00BCD4] transition duration-300 ease-in-out hover:scale-125 '/>
                   
                    <h6 className="sm:text-xs  md:text-sm lg:text-[18px] text-justify  dark:text-white ml-2 mt-5 p-1">
                    Room No# 167, DSE Tower (Level-9)
                          <br/> 
                      Plot No# 46, Road No#21, Nikunja-02, Dhaka-1219
                        </h6>
                    </div>

                    </div>


                    <div class="block max-w-full rounded    ">
                    <div class="p-3 flex">
                     
                        <BiPhoneCall className='w-16 h-16 mt-5 text-[#00BCD4] transition duration-300 ease-in-out hover:scale-125 '/>
                   
                    <p className="sm:text-xs mt-7 md:text-sm lg:text-[18px] text-justify  ml-5 dark:text-white p-1">
                    +88 02 41040272  <br/> +88 02 47112672
                        </p>
                    </div>
                    </div>

                    
                    <div class="block max-w-full rounded  ">
                    <div class="p-3 flex">
                     
                        <AiOutlineMail className='w-16 h-16 mt-5 text-[#00BCD4] transition duration-300 ease-in-out hover:scale-125 '/>
                   
                    <p className="sm:text-xs mt-9 md:text-sm lg:text-[18px] text-justify  ml-5 dark:text-white p-1">
                    pisl.dsebd@gmail.com
                        </p>
                    </div>
                    </div>
                 
                 

                  
                </div>
                <div className="map-container shadow-2xl p-5">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7299.012828563772!2d90.417219!3d23.8361447!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3755c770d073a57d%3A0xf5dc5e0f148e3330!2sDhaka%20Stock%20Exchange%20PLC.%20House%2046%20Road%20No.%2021%20Dhaka!3m2!1d23.8361447!2d90.417219!5e0!3m2!1sen!2sbd!4v1727763830297!5m2!1sen!2sbd"
                                width="100%"
                                height="480"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                            </div>


                       <div className='shadow-2xl mt-2 border'>

                   
                       <form onSubmit={handleSubmit} className="w-full max-w-full mx-auto p-16">
      <div className="grid grid-cols-2 gap-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            placeholder="Your Name"
          />
          {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            placeholder="name@example.com"
          />
          {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
            Phone
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            placeholder="Phone Number"
          />
          {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="queryType">
            Query Type
          </label>
          <select
            id="queryType"
            name="queryType"
            value={formData.queryType}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          >
            <option value="">Please Select</option>
            <option value="general">General Query</option>
            <option value="support">Support</option>
            <option value="feedback">Feedback</option>
          </select>
          {errors.queryType && <p className="text-red-500 text-xs italic">{errors.queryType}</p>}
        </div>

        <div className="col-span-2 mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            placeholder="Your Message"
          />
          {errors.message && <p className="text-red-500 text-xs italic">{errors.message}</p>}
        </div>

        <div className="col-span-2 mb-4">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-24 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
                        </div>
            </div>





        </div>
    );
};

export default Contactus;