import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Component/Home';
import Navbar from './Component/Nabar/Navbar';
import About from './Component/About';
import Footer from './Component/Footer';
import Notfound from './Component/Notfound';
import Boardofdirectors from './Component/Boardofdirectors';
import Corporatemanagement from './Component/Corporatemanagement';
import Contactus from './Component/Contactus';
import Servicesall from './Component/Servicesall';


function App() {
  return (
    <div className='App'>
         
          <Navbar/>
          <Routes>
              <Route path='/' element={<Home />}>Home</Route>
              <Route path='/about' element={<About />}>Home</Route>
              <Route path='/board-of-directors' element={<Boardofdirectors />}>Home</Route>
              <Route path='/corporate-management' element={<Corporatemanagement />}>Home</Route>

              <Route path='/contact-us' element={<Contactus />}>Home</Route>
              <Route path='/services' element={<Servicesall />}>Home</Route>


              
              
           <Route path='*' element={<Notfound />}>signup</Route>
      
              
          </Routes>
         <Footer></Footer>

    </div>
  );
}

export default App;
